<template>
  <div
    :style="props.appointment.style"
    class="card"
    @click="onClick"
  >
    <div class="card__title">{{ title }}</div>
    <p>{{ formattedTime }}</p>
  </div>
</template>

<script setup>
import { format } from 'date-fns'
import { computed } from 'vue'

import { useAppointmentsStore } from '@/stores/appointments'

const props = defineProps({
  appointment: {
    type: Object,
    default() {
      return {}
    }
  }
})

const appointmentsStore = useAppointmentsStore()

const formattedTime = computed(() => {
  if (!props.appointment.start) {
    return ''
  }
  return [format(props.appointment.start, 'HH:mm'), format(props.appointment.end, 'HH:mm')]
    .filter(Boolean)
    .join(' – ')
})

const title = computed(() => {
  return [...(props.appointment.viuTitle || [])].reverse().join(' ')
})

function onClick() {
  appointmentsStore.selectedAppointmentId = props.appointment.id
}
</script>

<style scoped>
.card {
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 12px;
  white-space: nowrap;
  border-left: 2px solid white;
  cursor: pointer;

  &__title {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
