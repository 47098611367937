import { computed, inject } from 'vue'
import { DISCOUNT_TYPES } from '@/constants/payment.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useDeviceStore } from '@/stores/device.js'
import { useNotification } from '@/composables/useNotification.js'
import { usePayment } from '@/composables/usePayment'
import { usePaymentStore } from '@/stores/payment.js'
import { useCartStore } from '@/stores/cart.js'
import { getErrorMessage } from '@/utils/error.js'
import { useUiStore } from '@/stores/ui.js'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export function useCart() {
  const cl = inject('cl')
  const Sentry = inject('Sentry')
  const uiStore = useUiStore()
  const customerStore = useCustomerStore()
  const deviceStore = useDeviceStore()
  const paymentStore = usePaymentStore()
  const cartStore = useCartStore()
  const { showNotification } = useNotification()
  const { terminalTransactionVerify } = usePayment()
  const currentCart = computed(() => customerStore.getCurrentCart)
  const currentStoreReference = computed(() => deviceStore.settings.warehouse)
  const marketId = computed(() => deviceStore.getMarketId)
  const { t } = useI18n()
  const router = useRouter()

  const cartLineItems = computed(() => {
    const data = currentCart.value?.line_items
    const items = data?.filter((lineItem) => {
      // if metadata.type is rounding don't return it.
      if (lineItem.metadata?.type === 'rounding') {
        return false
      }
      if (lineItem.metadata?.type === 'insurance') {
        return !lineItem.reference
      } else if (['gift_card', 'insurance'].includes(lineItem.metadata?.type)) {
        return true // Include gift cards and insurance regardless of reference
      } else if (['shipments', 'payment_methods', ...DISCOUNT_TYPES].includes(lineItem.item_type)) {
        return false
      } else if (
        lineItem.item?.metadata?.type === 'lens' ||
        lineItem.item?.metadata?.type === 'accessory'
      ) {
        return !lineItem.reference // Include lenses only if no reference
      } else {
        return true
      }
    })
    return items
  })

  const cartCaseLineItems = computed(() => {
    const data = currentCart.value?.line_items

    const items = data?.filter((lineItem) => {
      if (
        lineItem.item?.metadata?.type === 'accessory' &&
        lineItem.item.metadata?.sub_type === 'Case' &&
        lineItem.reference
      ) {
        return lineItem
      }
    })
    return items
  })

  const cartHasInsuranceTag = computed(() => {
    //check if currentCart.tags has a item where name is insurance.
    return currentCart.value?.tags?.some((tag) => tag.name === 'insurance')
  })

  const cartHasAfterSalesGiftCard = computed(() => {
    return currentCart.value?.attachments?.some((item) => {
      return (
        item.metadata.gift_card_reference?.startsWith('AS/INSURANCE') ||
        item.metadata.gift_card_reference?.startsWith('AS/INTOLERANCE')
      )
    })
  })

  const cartHasAfterSalesIntoleranceGiftCard = computed(() => {
    return currentCart.value?.attachments?.some((item) => {
      return item.metadata.gift_card_reference?.startsWith('AS/INTOLERANCE')
    })
  })

  const cartHasAfterSalesInsuranceGiftCard = computed(() => {
    const foundItem = currentCart.value?.attachments?.find((item) => {
      return item.metadata.gift_card_reference?.startsWith('AS/INSURANCE')
    })

    return foundItem ? foundItem.metadata.gift_card_reference : false
  })

  const cartHasGiftCardWithMinimumAmount = computed(() => {
    return currentCart.value?.attachments?.some((item) => {
      return item.metadata.min_payable_amount
    })
  })

  const expectedDeliverySlips = computed(() => {
    // return items from cart where directHandover is true and reference is set.
    return currentCart.value?.line_items?.filter(
      (item) =>
        !item?.metadata?.immediate_handover &&
        item?.reference &&
        item?.metadata?.origin_stock_location === currentStoreReference.value
    )
  })

  const cartHasFrameOrLensSparePart = computed(() => {
    // return true if any item in cartLineItems has property metadata.type frame or sun or customMadeFrame.
    if (cartLineItems.value) {
      return cartLineItems.value.some((item) => {
        return ['frame', 'sun', 'customMadeFrame', 'lens'].includes(item.item?.metadata?.type)
      })
    }

    return false
  })

  const isHandedOverDirectly = computed(() =>
    cartLineItems?.value?.every((lineItem) => lineItem.metadata?.immediate_handover === true)
  )

  const allGlassesAreHandedOverDirectly = computed(() => {
    if (cartLineItems.value) {
      const glasses = cartLineItems.value.filter((item) =>
        ['sun', 'frame'].includes(item.metadata?.type)
      )
      if (glasses.length === 0) {
        return false
      }

      return glasses.every((item) => {
        return item.metadata?.immediate_handover
      })
    }
    return false
  })

  const hasAnyHandedOverItem = computed(() =>
    cartLineItems?.value?.some((lineItem) => lineItem.metadata?.immediate_handover === true)
  )

  function getAfterSalesGiftcard(order) {
    // if order has an attachment with gift_card_reference starting with AS/INSURANCE or AS/INTOLERANCE return the gift card metadata.origin_order_id.
    const foundItem = order.attachments.find((item) => {
      return (
        item.metadata.gift_card_reference?.startsWith('AS/INSURANCE') ||
        item.metadata.gift_card_reference?.startsWith('AS/INTOLERANCE')
      )
    })
    return foundItem
      ? {
          order_id: foundItem.metadata?.origin_order_id,
          giftcard_type: foundItem.metadata.gift_card_reference?.startsWith('AS/INSURANCE')
            ? 'insurance'
            : 'intolerance'
        }
      : false
  }

  async function createCart() {
    const data = {
      market: cl.markets.relationship(marketId.value),
      ...(customerStore?.customer?.id && {
        customer: cl.customers.relationship(customerStore.customer.id)
      }),
      metadata: {
        employee_created: {
          eid: deviceStore.settings.currentEmployee.eid,
          name: deviceStore.settings.currentEmployee.name
        }
      },
      language_code: customerStore.customer?.metadata?.language
        ? customerStore.customer.metadata.language.toLowerCase()
        : deviceStore.settings.language.toLowerCase()
    }
    try {
      const cart = await cl.orders.create(data)

      customerStore.setCurrentCart(cart)
    } catch (error) {
      Sentry.setContext('createCart', {
        data: data
      })
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('cartCreate')
        return scope
      })
      showNotification({
        message: 'Could not create new cart.<br />Error: ' + error,
        variant: 'danger'
      })
    }
  }

  async function archiveCart() {
    const order = await cl.orders.retrieve(currentCart.value.id, {
      fields: { orders: ['status'] }
    })
    if (['draft', 'pending'].includes(order.status)) {
      try {
        await cl.orders.update({
          id: currentCart.value.id,
          _archive: true
        })
        customerStore.setCurrentCart(null)
        uiStore.showCart = false
      } catch (error) {
        showNotification({
          message: 'Could not remove the cart.<br />Error: ' + error,
          variant: 'danger'
        })
      }
    } else {
      customerStore.setCurrentCart(null)
      uiStore.showCart = false
    }
  }

  async function cartUpdate(update = null) {
    if (!currentCart.value?.id) return

    try {
      var options = {
        include: [
          'attachments',
          'line_items.item',
          'line_items.line_item_options.sku_option',
          'market',
          'shipping_address',
          'billing_address',
          'tags'
        ],
        fields: {
          markets: ['code'],
          orders: [
            'attachments',
            'subtotal_amount_float',
            'total_amount_float',
            'currency_code',
            'coupon_code',
            'fulfillment_status',
            'payment_status',
            'status',
            'number',
            'created_at',
            'shipping_address',
            'billing_address',
            'metadata',
            'line_items',
            'market',
            'tags',
            'skus_count',
            'gift_card_code',
            'total_amount_with_taxes_float'
          ]
        }
      }
      if (update) {
        const cart = await cl.orders.update(
          {
            id: currentCart.value.id,
            ...update
          },
          options
        )

        customerStore.setCurrentCart(cart)
      } else {
        const cart = await cl.orders.retrieve(currentCart.value.id, options)
        customerStore.setCurrentCart(cart)
      }
    } catch (error) {
      showNotification({
        message: 'cartUpdate:' + getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function deleteLineItem(id, ref, skipAttachements = false) {

    // get current order fresh from CL. and check if status is draft or pending. if so, exit the function.
    const order = await cl.orders.retrieve(currentCart.value.id, {
      fields: { orders: ['status'] }
    })
    if (!['draft', 'pending'].includes(order.status)) {
      showNotification({
        message: t('cart.cartIsNotDraftOrPending'),
        variant: 'danger'
      })
      return
    }


    try {
      await cl.line_items.delete(id)
      await cartUpdate()

      if (ref !== null && customerStore.customer.currentCart.line_items.length > 0) {
        const attachedLineItems = customerStore.customer.currentCart.line_items.filter(
          (item) => item.reference === ref
        )

        if (attachedLineItems.length > 0 && skipAttachements === false) {
          for (const item of attachedLineItems) {
            await cl.line_items.delete(item.id)
          }
        }
        await cartUpdate()
      }
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function removeInsurance(ref) {
    try {
      const insurance = customerStore.customer.currentCart.line_items.filter((item) => {
        return item.reference === ref && item.sku_code === 'INSURANCE'
      })

      const frame = customerStore.customer.currentCart.line_items.filter((item) => {
        return item.reference === ref && ['frame', 'customMadeFrame'].includes(item.metadata.type)
      })

      if (frame) {
        delete frame[0].metadata.insurance
        const attributes = {
          id: frame[0].id,
          metadata: {
            ...frame[0].metadata
          }
        }

        await cl.line_items.update(attributes)
      }

      await cl.line_items.delete(insurance[0].id)
      await cartUpdate()
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function addLineItem(item, skipCheck = false) {
    if (skipCheck === false) {
      if (cartHasAfterSalesGiftCard.value && item.sku_code !== 'SPARE20') {
        showNotification({
          message: t('afterSales.cartRestricted'),
          variant: 'danger',
          icon: 'exclamation-octagon',
          duration: 6000
        })
        return
      }
    }

    if (cartHasAfterSalesGiftCard.value && cartHasFrameOrLensSparePart.value) {
      showNotification({
        message: t('afterSales.cartRestrictedNoMoreItems'),
        variant: 'danger',
        icon: 'exclamation-octagon',
        duration: 6000
      })
      return
    }

    try {
      return await cl.line_items.create({
        ...item,
        order: {
          type: 'orders',
          id: currentCart.value.id
        }
      })
    } catch (error) {
      const message = getErrorMessage(error)
      showNotification({
        message: `Item could not be added to the cart.<br /><b>Error:</b> ${message}`,
        variant: 'danger',
        icon: 'exclamation-octagon',
        duration: 6000
      })

      return false
    }
  }

  // If it’s not a frame and not a customMadeFrame and the article comes from the warehouse of the local store
  // immediate_handover is set to true
  async function setImmediateHandoverOnLineItem(lineItemData) {
    try {
      const data = {
        id: lineItemData.id,
        metadata: {
          ...lineItemData.metadata
        }
      }

      const lineItem = currentCart.value?.line_items.find((item) => item.id === lineItemData.id)

      /* if immediate_handover ist already true -> return */
      if (
        lineItem.metadata.immediate_handover === lineItemData.metadata.immediate_handover &&
        lineItem.metadata.immediate_handover !== undefined
      ) {
        return
      }

      if (
        !['frame', 'customMadeFrame', 'lens'].includes(lineItem?.item.metadata?.type) &&
        lineItem?.metadata?.origin_stock_location === currentStoreReference.value &&
        deviceStore.settings.stockLocationId !== deviceStore.settings.serviceHubId
      ) {
        data.metadata.immediate_handover = true

        await cl.line_items.update(data)
        const foundLineItem = currentCart.value.line_items?.find(
          (lineItem) => lineItem.id === lineItemData.id
        )
        if (foundLineItem) {
          foundLineItem.metadata.immediate_handover = data.metadata.immediate_handover
        }
      }
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  function hasRoundingAdjustment() {
    const roundingAdjustments = currentCart.value?.line_items?.filter(
      (item) => item.metadata?.type === 'rounding'
    )

    return roundingAdjustments?.length === 0 ? false : roundingAdjustments
  }

  async function addAdjustmentAmount(amount) {
    if (hasRoundingAdjustment()) {
      return
    }
    try {
      const adjustment = await cl.adjustments.create({
        type: 'ROUNDING',
        name: 'ROUNDING',
        currency_code: currentCart.value.currency_code,
        amount_cents: parseInt(amount * 100),
        distribute_discount: false,
        metadata: {
          title: {
            de: 'Rundung',
            en: 'Rounding',
            fr: 'Arrondi'
          }
        }
      })

      if (adjustment.id) {
        addLineItem({
          sku_code: 'ROUNDING',
          quantity: 1,
          item: cl.adjustments.relationship(adjustment.id),
          metadata: {
            type: 'rounding',
            adjustment_id: adjustment.id
          }
        })
      }

      await cartUpdate()
    } catch (error) {
      const message = getErrorMessage(error)
      showNotification({
        message: `Adjustment could not be added to the cart.<br /><b>Error:</b> ${message}`,
        variant: 'danger',
        icon: 'exclamation-octagon',
        duration: 6000
      })

      return false
    }
  }

  async function addGiftcardAttachmentToOrder(giftCard) {
    if (
      giftCard.expires_at &&
      new Date(giftCard.expires_at) < new Date() &&
      giftCard.expires_at !== null
    ) {
      showNotification({
        message: t('giftCard.expired'),
        variant: 'danger'
      })
      return false
    }

    const addedGiftcard = await cl.attachments.create({
      name: 'Giftcard',
      reference: giftCard.code,
      reference_origin: 'payment--gift_card',
      metadata: {
        balance_float: giftCard.balance_float,
        balance_cents: giftCard.balance_float * 100,
        gift_card_reference_origin: giftCard.reference_origin,
        gift_card_reference: giftCard.reference,
        ...(giftCard.metadata?.min_payable_amount && {
          min_payable_amount: giftCard.metadata.min_payable_amount
        }),
        ...(giftCard.metadata?.origin_return_number && {
          origin_return_number: giftCard.metadata.origin_return_number
        }),
        ...(giftCard.metadata?.origin_order_id && {
          origin_order_id: giftCard.metadata.origin_order_id
        }),
        ...(giftCard.metadata?.gift_card_type && {
          gift_card_type: giftCard.metadata.gift_card_type
        })
      },
      attachable: cl.orders.relationship(currentCart.value.id)
    })

    if (addedGiftcard.id) {
      showNotification({
        icon: 'gift',
        message: t('giftCard.addedSuccessfully'),
        variant: 'success'
      })
      await cartUpdate()
      return addedGiftcard
    } else {
      showNotification({
        icon: 'exclamation-triangle',
        message: t('giftCard.notAddedToOrder'),
        variant: 'warning'
      })
      return false
    }
  }

  async function removeGiftcardAttachmentFromOrder(giftcardId) {
    const removedGiftcard = await cl.attachments.delete(giftcardId)
    if (!removedGiftcard) {
      showNotification({
        icon: 'trash',
        message: t('giftCard.removedSuccessfully'),
        variant: 'success'
      })
      await cartUpdate()
      return true
    } else {
      showNotification({
        icon: 'exclamation-triangle',
        message: t('giftCard.notRemovedFromOrder'),
        variant: 'danger'
      })
      return false
    }
  }

  async function getGiftCard(giftCardCode) {
    return await cl.gift_cards.list({
      include: ['market'],
      filters: {
        code_eq: giftCardCode
      },
      pageSize: 1
    })
  }

  async function getGiftcardByCodeForOrder(code, email, currency, silent = false) {
    let giftCard = null
    let data = await getGiftCard(code)

    if (!data || data.length < 1) {
      // for legacy code e.g. employee types only VIUGC1234 try it with currency prefix
      data = await getGiftCard(`${currency}-${code}`)
    }

    if (data && data.length > 0) {
      giftCard = data[0]
    } else {
      if (!silent) {
        showNotification({
          message: 'Could not find gift card with code ' + code + ' for email ' + email,
          variant: 'danger'
        })
      }
      return false
    }

    if (giftCard.status !== 'active') {
      showNotification({
        message: t('giftCard.isNotActive'),
        variant: 'danger'
      })
      return false
    } else if (giftCard.balance_cents == 0) {
      showNotification({
        message: t('giftCard.noBalance'),
        variant: 'danger'
      })
      return false
    } else if (giftCard.currency_code !== deviceStore.settings.currency) {
      showNotification({
        message: t('giftCard.currencyMismatch'),
        variant: 'danger'
      })
      return false
    }

    return giftCard
  }

  function getCurrentItemReference() {
    if (!currentCart.value || !currentCart.value?.line_items) {
      return '001'
    } else {
      // get all line_items from currentCart where reference is not null and return the highest number
      let highestNumber = 0
      for (const item of currentCart.value.line_items) {
        if (item.reference && parseInt(item.reference) > highestNumber) {
          highestNumber = parseInt(item.reference)
        }
      }
      return (highestNumber + 1).toString().padStart(3, '0')
    }
  }

  function orderQuery() {
    return {
      include: [
        'attachments',
        'line_items.item',
        'line_items.line_item_options.sku_option',
        'market',
        'shipping_address',
        'billing_address',
        'shipments.available_shipping_methods',
        'tags'
      ],
      fields: {
        markets: ['code'],
        orders: [
          'attachments',
          'subtotal_amount_float',
          'total_amount_float',
          'currency_code',
          'fulfillment_status',
          'payment_status',
          'status',
          'number',
          'created_at',
          'shipping_address',
          'billing_address',
          'metadata',
          'line_items',
          'market',
          'tags',
          'skus_count',
          'shipments',
          'gift_card_code',
          'total_amount_with_taxes_float'
        ]
      }
    }
  }

  async function keepCurrentOrder(currentOrder) {
    if (currentOrder.status === 'approved') {
      const newOrder = await cl.orders.retrieve(currentOrder.id, orderQuery())
      customerStore.setCurrentCart(newOrder)
      return true
    } else if (['paid', 'partially_paid'].includes(currentOrder.payment_status)) {
      // If the order is already paid, we don't need to copy it - just approve it
      const newOrder = await cl.orders.update(
        {
          id: currentOrder.id,
          _approve: true
        },
        orderQuery()
      )
      customerStore.setCurrentCart(newOrder)
      return true
    } else if (currentOrder.status !== 'placed') {
      // We only want to copy orders that are placed
      uiStore.isOrderCopyInProgress = false
      return false
    }

    return false
  }

  /**
   * Verify terminal payment
   *
   * - Calls the Adyen Terminal via Supabase function to verify the payment
   * - If the payment is successful, the order is fetched again to get the updated status
   * - Use this to provide order copies with already captured payments
   */
  async function verifyTerminalPayment(currentOrder) {
    // if terminal payment and captures has status succeeded = false and message = 'Accepted' then verify adyen payment
    const terminalPayment = currentOrder.authorizations.find(
      (auth) => auth.reference_origin === 'CREDIT_CARD'
    )

    // find captures with status succeeded = false and message = 'Accepted'
    if (terminalPayment) {
      const capture = terminalPayment.captures.find(
        (capture) => capture.succeeded === false && capture.message === 'Accepted'
      )

      if (!capture) {
        return currentOrder
      }

      // wait for 5s and verify the payment - give the terminal some time to process the payment
      await new Promise((resolve) => setTimeout(resolve, 5000))
      const result = await terminalTransactionVerify(capture, terminalPayment.reference)
      if (result?.data?.success) {
        // wait for 5s and fetch the order again - wait till the supabase webhook has updated the order
        await new Promise((resolve) => setTimeout(resolve, 5000))
        // fetch the order again to
        return await cl.orders.retrieve(currentOrder?.id, {
          include: ['authorizations.captures'],
          fields: { orders: ['status', 'payment_status', 'number', 'id', 'authorizations'] }
        })
      }
    }

    return currentOrder
  }

  async function createOrderCopy(order) {
    try {
      uiStore.isOrderCopyInProgress = true

      // before do the copy check if the order is not approved or not paid or partially paid
      // Only a placed order can be copied!
      let currentOrder = await cl.orders.retrieve(order?.id, {
        include: ['authorizations.captures'],
        fields: { orders: ['status', 'payment_status', 'number', 'id', 'authorizations'] }
      })
      let doKeepCurrentOrder = false

      try {
        // check if the order is already payed via terminal payment
        currentOrder = await verifyTerminalPayment(currentOrder)

        // check if the order is already approved or paid
        doKeepCurrentOrder = await keepCurrentOrder(currentOrder)
      } catch (error) {
        Sentry.setContext('orderCopy', {
          order: currentOrder
        })

        Sentry.captureException(error, (scope) => {
          scope.setTransactionName('handleOrderCopyVerifyTerminalPayment')
          return scope
        })
      }

      if (doKeepCurrentOrder) {
        uiStore.isOrderCopyInProgress = false
        uiStore.showCartPaymentDialog = false
        uiStore.showCart = false
        paymentStore.$patch({
          payment: {
            orderId: currentOrder.id,
            orderNumber: currentOrder.number
          },
          expectedDeliverySlips: expectedDeliverySlips?.value.length
        })
        customerStore.setCurrentCart(null)
        cartStore.shippingAddressSetOnCart = false
        cartStore.customerSetOnCart = false
        await router.push({ name: 'order-success' })
      }

      const orderCopy = await cl.order_copies.create({
        source_order: order,
        cancel_source_order: true,
        apply_promotions: true,
        ignore_invalid_coupon: true
      })

      // for max. 10 seconds call isOrderCopyCompleted(orderId) every second and return true if orderCopy.status === 'completed'. abort the loop if orderCopy.status === 'completed'.
      let counter = 0
      let isCompleted = false
      while (counter < 10) {
        if (isCompleted) {
          // for each order.attachment update the reference to the new order
          for (const attachment of order.attachments) {
            await cl.attachments.update({
              id: attachment.id,
              attachable: cl.orders.relationship(isCompleted.target_order.id)
            })
          }

          const newOrder = await cl.orders.retrieve(isCompleted.target_order.id, orderQuery())
          // set _refresh to true to avoid the discount calculation issue
          const refreshedOrder = await cl.orders.update(
            {
              id: newOrder.id,
              _refresh: true
            },
            orderQuery()
          )

          customerStore.setCurrentCart(refreshedOrder)
          uiStore.isOrderCopyInProgress = false
          break
        }
        isCompleted = await isOrderCopyCompleted(orderCopy.id)
        await new Promise((resolve) => setTimeout(resolve, 1000))
        counter++
      }
    } catch (error) {
      Sentry.setContext('orderCopy', {
        order: order
      })
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName('handleOrderCopyUseCart')
        return scope
      })
    } finally {
      uiStore.isOrderCopyInProgress = false
    }
  }

  async function isOrderCopyCompleted(orderId) {
    const orderCopy = await cl.order_copies.retrieve(orderId, {
      include: ['target_order']
    })

    if (orderCopy.status === 'completed') {
      return orderCopy
    } else {
      return false
    }
  }

  return {
    createCart,
    archiveCart,
    cartUpdate,
    deleteLineItem,
    removeInsurance,
    addLineItem,
    setImmediateHandoverOnLineItem,
    getAfterSalesGiftcard,
    cartLineItems,
    cartCaseLineItems,
    cartHasInsuranceTag,
    cartHasAfterSalesGiftCard,
    cartHasAfterSalesIntoleranceGiftCard,
    cartHasAfterSalesInsuranceGiftCard,
    cartHasGiftCardWithMinimumAmount,
    expectedDeliverySlips,
    isHandedOverDirectly,
    allGlassesAreHandedOverDirectly,
    hasAnyHandedOverItem,
    addAdjustmentAmount,
    hasRoundingAdjustment,
    addGiftcardAttachmentToOrder,
    removeGiftcardAttachmentFromOrder,
    getGiftCard,
    getGiftcardByCodeForOrder,
    getCurrentItemReference,
    createOrderCopy
  }
}
