<template>
  <component
    :is="`sl-${props.type}`"
    v-if="isUsable"
    ref="el"
    :open="props.open"
    v-bind="$attrs"
    @sl-request-close="onRequestClose"
  >
    <slot />
  </component>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/drawer/drawer'
import '@shoelace-style/shoelace/dist/components/dialog/dialog'

import { ref, computed } from 'vue'

const emit = defineEmits(['request-close'])

const props = defineProps({
  type: {
    type: String,
    default: 'drawer'
  },
  open: {
    type: Boolean,
    default: false
  },
  closingBlocked: {
    type: Boolean,
    default: false
  }
})

const usableTypes = ['drawer', 'dialog']
const isUsable = computed(() => usableTypes.includes(props.type))
const el = ref()

function onRequestClose(event) {
  if (props.closingBlocked) {
    event.preventDefault()
  } else if (event.target === el.value) {
    emit('request-close', event)
  }
}
</script>
