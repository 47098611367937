<template>
  <header class="header">
    <div style="padding-right: 20px; display: flex; align-items: center">
      <Menu />
      <EmployeeSelector />
    </div>
    <template v-if="appointmensStore.enabled">
      <div
        v-if="!hasViuAppointments"
        style="margin-right: auto; display: flex; gap: 4px; padding-right: 8px; "
      >
        <sl-spinner
          style="font-size: 2rem"
        ></sl-spinner>
      </div>
      <Transition
        name="fade"
      >
        <TransitionGroup
          v-if="hasViuAppointments"
          name="appointment-list"
          tag="div"
          style="margin-right: auto; display: flex; gap: 4px; padding-right: 8px;"
        >
          <CustomerAppointmentCard
            v-for="appointment in appointments"
            :key="appointment.id"
            :appointment="appointment"
            class="header__appointment"
            :class="{
              'header__appointment--inactive': appointmensStore.selectedAppointmentId && appointmensStore.selectedAppointmentId !== appointment.id
            }"
          />
        </TransitionGroup>
      </Transition>
    </template>
    <div
      class="viu-logo"
      @click="handleCloseDialogs"
    >
      <RouterLink to="/" style="align-self: center"
        ><img src="../../../assets/logo.png"
      /></RouterLink>
      <div class="env" :class="`env--${env}`">{{ env }}</div>
    </div>
    <div style="padding-left: 45px; display: flex; justify-content: flex-end">
      <template v-if="cl">
        <CustomerMenu />
        <Basket />
      </template>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import CustomerMenu from '@/components/Customer/CustomerMenu/CustomerMenu.vue'
import { useUiStore } from '@/stores/ui.js'
import { useAppointmentsStore } from '@/stores/appointments'
import { inject } from 'vue'
import EmployeeSelector from '../../Employee/EmployeeSelector.vue'
import Basket from './Basket.vue'
import Menu from './Menu.vue'
import CustomerAppointmentCard from './CustomerAppointmentCard.vue'

const cl = inject('cl')
const uiStore = useUiStore()
const appointmensStore = useAppointmentsStore()
const env = import.meta.env.VITE_WEB_ENV

function handleCloseDialogs() {
  uiStore.showCart = false
}

const skipAppointmentTypes = ['store_admin_30_min', 'store_admin_60_min']

const appointments = computed(() => {
  return appointmensStore.upcomingViuAppointments
    .filter(appointment => {
      return !skipAppointmentTypes.includes(appointment.serviceKey)
    })
    .slice(0, 2)
})

const hasViuAppointments = computed(() => {
  return appointments.value.length > 0
})
</script>

<style>
.appointment-list-move, /* apply transition to moving elements */
.appointment-list-enter-active,
.appointment-list-leave-active {
  transition: all 0.5s ease;
}

.appointment-list-enter-from,
.appointment-list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.appointment-list-leave-active {
  position: absolute;
}
</style>

<style lang="postcss" scoped>
.header {
  position: fixed;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--sl-spacing-large) 0 0;
  height: 68px;
  z-index: 10;

  img {
    width: 120px;
    padding: 24px;
  }
  .viu-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__appointment {
    flex: 1 1 50%;
    max-width: 120px; 
    overflow: hidden;

    &--inactive {
      opacity: .4;
    }
  }
}

.env {
  position: absolute;
  top: 36px;
  right: 0;
  border: 2px solid #000;
  font-size: 10px;
  line-height: 1;
  padding: 3px 5px;
  border-radius: 4px;

  &--live {
    background-color: red;
  }

  &--test {
    background-color: yellow;
  }
}
</style>
