<template>
  <sl-drawer
    ref="chooseAddressDrawerRef"
    class="cart-choose-address-drawer"
    :label="getLabel()"
    style="--size: 860px"
    @sl-request-close="handleDrawerHide"
  >
    <div class="cart-choose-address-container">
      <section class="cart-choose-addresses-wrapper">
        <div
          v-for="(customerAddress, index) in customer.customer_addresses"
          :key="index"
          class="cart-choose-address-item"
          :class="{
            'cart-choose-address-item--active': selectedAddress?.name === customerAddress?.name
          }"
        >
          <div style="display: flex; flex-direction: column; justify-content: space-between">
            <div style="margin-bottom: 20px">
              <p class="text-size-small text-color-neutral">
                {{ $t('cart.drawer.choose_address.standard_address') }}
              </p>
              <p class="text-size-medium text-color-black" style="margin-bottom: 20px">
                {{ customerAddress.address.first_name }} {{ customerAddress.address.last_name }}
              </p>
              <p class="text-size-medium text-color-black">{{ customerAddress.address?.line_1 }}</p>
              <p class="text-size-medium text-color-black">{{ customerAddress.address?.line_2 }}</p>
              <p class="text-size-medium text-color-black">
                {{ customerAddress.address?.zip_code }} {{ customerAddress.address?.city }}
              </p>
              <p class="text-size-medium text-color-black">
                {{ getCountryName(customerAddress.address?.country_code) }}
              </p>
            </div>
            <template v-if="customerAddress.address?.metadata.is_default_billing">
              <div class="cart-primary-address__item">
                <div class="cart-primary-address__item-icon">
                  <sl-icon
                    slot="icon"
                    style="color: var(--neutral-700, #676767)"
                    name="check-circle"
                  />
                </div>
                <p class="cart-primary-address__item-label">
                  {{ $t('cart.drawer.choose_address.standard_billing_address') }}
                </p>
              </div>
            </template>
            <template v-if="customerAddress.address?.metadata.is_default_shipping">
              <div class="cart-primary-address__item">
                <div class="cart-primary-address__item-icon">
                  <sl-icon
                    slot="icon"
                    style="color: var(--neutral-700, #676767)"
                    name="check-circle"
                  />
                </div>
                <p class="cart-primary-address__item-label">
                  {{ $t('cart.drawer.choose_address.standard_delivery_address') }}
                </p>
              </div>
            </template>
            <template
              v-if="
                !isAfterSalesReturn
                  ? selectedAddress?.name !== customerAddress?.name
                  : selectedAddress?.name !== customerAddress?.name &&
                    (customerAddress?.address?.country_code.toUpperCase() ===
                      deviceStore.settings.country.toUpperCase() ||
                      (deviceStore.settings.country.toUpperCase() === 'CH' &&
                        customerAddress?.address?.country_code.toUpperCase() === 'LI'))
              "
            >
              <sl-button class="cart-select-address" @click="setActiveAddress(customerAddress)">
                <PhUserList :size="20" />
                {{ $t('cart.button.select_address') }}
              </sl-button>
            </template>
          </div>
          <div>
            <ThreeDotsMenu v-if="!isAfterSalesReturn" placement="bottom-end">
              <template #menu>
                <sl-menu>
                  <sl-menu-item @click="handleEditAddress(customerAddress.address)">
                    <PhPencilSimpleLine :size="20" /> {{ $t('globals.edit') }}
                  </sl-menu-item>
                </sl-menu>
              </template>
            </ThreeDotsMenu>
          </div>
        </div>
        <div class="cart-choose-address-add-item" @click="handleAddNewAddress">
          <div style="text-align: center">
            <PhPlus :size="20" />
            <p class="text-size-small font-weight-semi-bold">
              {{ $t('cart.drawer.choose_address.add_address') }}
            </p>
          </div>
        </div>
      </section>
    </div>
    <template v-if="isLoading">
      <div class="loader-wrapper">
        <div class="loader-item">
          <sl-spinner style="font-size: 3rem; --track-width: 4px"></sl-spinner>
          <p>{{ $t('globals.loading') }}...</p>
        </div>
      </div>
    </template>
  </sl-drawer>
</template>

<script setup>
import ThreeDotsMenu from '@/components/shared/ThreeDotsMenu/ThreeDotsMenu.vue'
import { useCart } from '@/composables/useCart.js'
import { useNotification } from '@/composables/useNotification.js'
import { useShipment } from '@/composables/useShipment.js'
import { SHIPPING_METHOD } from '@/constants/shipping.js'
import { useCartStore } from '@/stores/cart.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useAfterSalesStore } from '@/stores/afterSales'
import { useDeviceStore } from '@/stores/device.js'
import { useUiStore } from '@/stores/ui.js'
import { getCountryName } from '@/utils/country.js'
import { getErrorMessage } from '@/utils/error.js'
import { PhPencilSimpleLine, PhPlus, PhUserList } from '@phosphor-icons/vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { setShippingAddressAndMethod } = useShipment()

const { t } = useI18n()
const uiStore = useUiStore()
const customerStore = useCustomerStore()
const afterSalesStore = useAfterSalesStore()
const cartStore = useCartStore()
const deviceStore = useDeviceStore()
const { cartUpdate } = useCart()
const { showNotification } = useNotification()
const customer = computed(() => customerStore.getCustomer)
const isLoading = ref(false)
const chooseAddressDrawerRef = ref()
const selectedAddress = ref({})

const billingAddress = computed(() => customerStore.customer?.currentCart?.billing_address)
const shippingAddress = computed(() => customerStore.customer?.currentCart?.shipping_address)

onMounted(() => {
  setTimeout(() => {
    if (uiStore.showCartChooseAddressDrawer) {
      chooseAddressDrawerRef.value.show()
    }
  }, 100)

  setActiveAddressCard()
})

const isAfterSalesReturn = computed(
  () => afterSalesStore.action === 'return' && afterSalesStore.returnType === 'post'
)

function handleDrawerHide(event) {
  if (event) {
    event.stopPropagation()
  }
  if (isLoading.value && event) {
    event.preventDefault()
    return
  }
  chooseAddressDrawerRef.value.hide().then(() => {
    uiStore.showCartChooseAddressDrawer = false
    uiStore.showCustomerAddressCreationEditDialog = false
  })
}

async function handleSelectedAddress(newAddress) {
  try {
    isLoading.value = true

    // update address data for order
    if (!customerStore.isOrderOverviewAddress && uiStore.showCart) {
      if (cartStore.isBillingAddress) {
        await cartUpdate({
          _billing_address_clone_id: newAddress.id
        })
      } else {
        await setShippingAddressAndMethod(SHIPPING_METHOD.SHIPPING, newAddress.id)
        await cartUpdate()
      }
    }
    if (isAfterSalesReturn.value) {
      afterSalesStore.returnAddress = newAddress
    }

    handleDrawerHide()
  } catch (error) {
    showNotification({
      message: getErrorMessage(error),
      variant: 'danger'
    })
  } finally {
    isLoading.value = false
  }
}
function getLabel() {
  if (cartStore.isBillingAddress) {
    return t('cart.drawer.choose_address.billing_address')
  }
  return t('cart.drawer.choose_address.delivery_address')
}

async function setActiveAddress(activeAddress) {
  selectedAddress.value = activeAddress.address
  await handleSelectedAddress(activeAddress.address)
}

async function handleAddNewAddress() {
  try {
    uiStore.showCustomerAddressCreationEditDialog = true
    customerStore.setCustomerAddress(null)
  } catch (error) {
    showNotification({
      message: getErrorMessage(error),
      variant: 'danger'
    })
  }
}

function setActiveAddressCard() {
  if (cartStore.isBillingAddress) {
    selectedAddress.value = billingAddress.value
  } else {
    selectedAddress.value = shippingAddress.value
  }
}

function handleEditAddress(customerAddress) {
  if (selectedAddress.value?.id === customerAddress?.id) {
    cartStore.isActiveCartAddress = true
  } else {
    cartStore.isActiveCartAddress = false
  }
  customerStore.setCustomerAddress(customerAddress)
  uiStore.showCustomerAddressCreationEditDialog = true
}

watch(
  () => uiStore.showCustomerAddressCreationEditDialog,
  () => {
    setActiveAddressCard()
  }
)
</script>

<style lang="postcss" scoped src="./CartChooseAddressDrawer.css" />
