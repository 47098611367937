<template>
  <Overlay
    type="dialog"
    :open="open"
    :label="title"
    :closing-blocked="inProgress"
    @request-close="close"
  >
    <div v-if="props.appointment?.id" class="modify">
      <div class="modify__location">
        <div>
          {{ props.currentCompany.externalId }}
          {{ props.appointment.companyName.replace('VIU Eyewear', '').trim() }}
        </div>
        <div v-for="employee in employees" :key="employee.name">
          {{ employee.name }}
        </div>
      </div>
      <div class="modify__date">
        <div>
          {{ formattedDate }}
        </div>
        <div>
          {{ formattedTime }}
        </div>
      </div>
      <div>
        <div style="display: flex; gap: 10px; align-items: center; margin-bottom: 6px;">
          <span>{{ customerName }}</span>
          <sl-button
            v-if="customerName"
            variant="primary"
            size="small"
            :disabled="customerStore.customer?.email === customerEmail || !loadedCustomer"
            @click="onCustomerClick"
          >
            {{ $t('appointments.modify.load_customer') }}
          </sl-button>
        </div>
        <div class="modify__personal">
          <div>
            {{ customerEmail }}
          </div>
          <div>{{ customerPhone }}</div>
        </div>
      </div>
      <div style="display: flex; gap: 10px;">
        <sl-alert v-if="props.appointment.noShow" open>
          {{ $t('appointments.modify.no_show') }}
        </sl-alert>
        <sl-alert v-if="loadedCustomerReady && !loadedCustomer" variant="danger" open>
          {{ $t('appointments.modify.customer_not_found') }}
        </sl-alert>
      </div>
    </div>
    <div slot="footer" style="width: 100%">
      <div style="display: flex; gap: 20px;">
        <sl-button
          variant="default"
          :disabled="!canModifyAppointment"
          @click="onChange"
        >
          <PhCalendarDot slot="prefix" :size="20" />
          {{ $t('appointments.modify.change') }}
        </sl-button>
        <sl-button
          v-if="canModifyAppointment"
          variant="default"
          @click="deleteConfirmOpen = true"
        >
          <PhX slot="prefix" :size="20" />
          {{ $t('appointments.modify.cancel') }}
        </sl-button>
        <sl-button
          v-else-if="!props.appointment.noShow"
          variant="default"
          @click="noShowConfirmOpen = true"
        >
          <PhWarning slot="prefix" :size="20" />
          {{ $t('appointments.modify.no_show') }}
        </sl-button>
      </div>
      <p v-if="!canModifyAppointment" style="text-align: left; margin-top: 10px; font-size: 12px">
        {{ $t('appointments.modify.not_possible') }}
      </p>
    </div>
  </Overlay>
  <Overlay
    type="dialog"
    :open="deleteConfirmOpen"
    @request-close="onAppointmentDeleteCancel"
  >
    <h4 class="modify__cancel__title">
      {{ $t('appointments.modify.cancel_confirm.title') }}
    </h4>
    <p v-if="props.appointment.customers?.length > 0">
      {{ $t('appointments.modify.cancel_confirm.description') }}
    </p>
    <div slot="footer" style="display: flex; width: 100%; justify-content: space-between">
      <sl-button variant="default" @click="onAppointmentDeleteCancel">
        {{ $t('globals.cancel') }}
      </sl-button>
      <sl-button
        variant="primary"
        :disabled="deleteInProgress"
        :loading="deleteInProgress"
        @click="onAppointmentDeleteConfirm"
      >
        {{ $t('appointments.modify.cancel_confirm.confirm') }}
      </sl-button>
    </div>
  </Overlay>
  <Overlay
    type="dialog"
    :open="noShowConfirmOpen"
    @request-close="onNoShowCancel"
  >
    <h4 class="modify__cancel__title">
      {{ $t('appointments.modify.no_show_confirm.title') }}
    </h4>
    <p>
      {{ $t('appointments.modify.no_show_confirm.description') }}
    </p>
    <div slot="footer" style="display: flex; width: 100%; justify-content: space-between">
      <sl-button variant="default" @click="onNoShowCancel">
        {{ $t('globals.cancel') }}
      </sl-button>
      <sl-button
        variant="primary"
        :disabled="noShowInProgress"
        :loading="noShowInProgress"
        @click="onNoShowConfirm"
      >
        {{ $t('appointments.modify.no_show_confirm.confirm') }}
      </sl-button>
    </div>
  </Overlay>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { format } from 'date-fns'
import { PhX, PhCalendarDot, PhWarning } from '@phosphor-icons/vue'

import Overlay from '@/components/shared/Overlay/Overlay.vue'

import { useTimify } from '@/composables/useTimify.js'
import { useI18n } from 'vue-i18n'
import { useNotification } from '@/composables/useNotification.js'
import { useCustomer } from '@/composables/useCustomer.js'
import { useCartStore } from '@/stores/cart.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useUiStore } from '@/stores/ui.js'

const emit = defineEmits(['changed', 'edit'])

const props = defineProps({
  appointment: {
    type: Object,
    default() {
      return {}
    }
  },
  currentCompany: {
    type: Object,
    default() {
      return {}
    }
  }
})

const { locale, t } = useI18n()
const timify = useTimify()
const notification = useNotification()
const { loadCustomerByEmail } = useCustomer()
const customerStore = useCustomerStore()
const cartStore = useCartStore()
const uiStore = useUiStore()

const currentLocale = computed(() => {
  let currentLocale = locale.value
  if (currentLocale.includes('de')) {
    currentLocale = 'de-de'
  } else if (currentLocale.includes('en')) {
    currentLocale = 'en-gb'
  } else if (currentLocale.includes('fr')) {
    currentLocale = 'fr-fr'
  }

  return currentLocale
})

const open = defineModel('open', {
  type: Boolean,
  default: false
})

function close() {
  open.value = false
}

const customer = computed(() => {
  return props.appointment?.singleCustomers?.[0]
})
const customerEmail = computed(() => {
  return customer.value?.email
})
const customerPhone = computed(() => {
  return customer.value?.phone?.phone
})
const customerName = computed(() => {
  return customer.value?.fullName
})
const employees = computed(() => {
  return (props.appointment?.resources || []).filter((resource) => Boolean(resource.externalId))
})

const title = computed(() => {
  return [
    props.appointment?.serviceKey
      ? t(`appointments.services.${props.appointment?.serviceKey}`)
      : props.appointment?.title,
    props.appointment?.duration
      ? t('appointments.duration_title', { duration: props.appointment.duration })
      : ''
  ]
    .filter(Boolean)
    .join(' ')
})

const formattedDate = computed(() => {
  if (!props.appointment.start) {
    return ''
  }
  return new Intl.DateTimeFormat(currentLocale.value, {
    dateStyle: 'full'
  }).format(props.appointment.start)
})

const formattedTime = computed(() => {
  if (!props.appointment.start) {
    return ''
  }
  return [format(props.appointment.start, 'HH:mm'), format(props.appointment.end, 'HH:mm')]
    .filter(Boolean)
    .join(' – ')
})

// delete
const canModifyAppointment = computed(() => {
  return new Date() < props.appointment.start
})
const deleteInProgress = ref(false)
const inProgress = computed(() => {
  return deleteInProgress.value
})

const deleteConfirmOpen = ref(false)

async function onAppointmentDeleteConfirm() {
  deleteInProgress.value = true
  try {
    await timify.cancel({
      appointmentId: props.appointment.id,
      companyId: props.currentCompany?.id
    })
    emit('changed')
    notification.showNotification({
      message: t('appointments.modify.cancel_confirm.success'),
      variant: 'success'
    })
  } catch {
    notification.showNotification({
      message: t('appointments.modify.cancel_confirm.failure'),
      variant: 'danger'
    })
  } finally {
    deleteInProgress.value = false
    deleteConfirmOpen.value = false
    open.value = false
  }
}

function onAppointmentDeleteCancel() {
  deleteConfirmOpen.value = false
}

function onChange() {
  emit('edit')
}

const loadedCustomer = ref(null)
const loadedCustomerReady = ref(false)
watch(customerEmail, async value => {
  if (value) {
    loadedCustomer.value = await loadCustomerByEmail(value)
    loadedCustomerReady.value = true
  } else {
    loadedCustomer.value = null
    loadedCustomerReady.value = false
  }
}, { immediate: true })

async function onCustomerClick() {
  if (loadedCustomer.value) {
    customerStore.setCustomer(loadedCustomer.value)
    //
    const billingAddress = loadedCustomer.value.customer_addresses?.filter(
      (item) => item.address.metadata?.is_default_billing
    )
    const shippingAddress = loadedCustomer.value.customer_addresses?.filter(
      (item) => item.address.metadata?.is_default_shipping
    )
    cartStore.setBillingAddress(billingAddress?.[0]?.address || {})
    cartStore.setShippingAddress(shippingAddress?.[0]?.address || {})
    open.value = false
    uiStore.$patch({ currentCustomerDrawer: 'profile' })
  }
}

const noShowConfirmOpen = ref(false)
const noShowInProgress = ref(false)

function onNoShowCancel() {
  noShowConfirmOpen.value = false
}

async function onNoShowConfirm() {
  noShowInProgress.value = true
  try {
    await timify.change({
      appointmentId: props.appointment.id,
      companyId: props.currentCompany?.id,
      tagIds: ['676172ef90313cbc66dc74b6']
    })
    emit('changed')
    notification.showNotification({
      message: t('appointments.modify.no_show_confirm.success'),
      variant: 'success'
    })
  } catch {
    notification.showNotification({
      message: t('appointments.modify.no_show_confirm.failure'),
      variant: 'danger'
    })
  } finally {
    noShowInProgress.value = false
    noShowConfirmOpen.value = false
    open.value = false
  }
}
</script>

<style scoped src="./AppointmentsModify.css"></style>
