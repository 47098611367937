import { ref } from 'vue'

export function useNow(timeout = 10 * 1000) {
  
  const data = ref(new Date())

  setTimeout(function update() {
    data.value = new Date()
    setTimeout(update, timeout)
  }, timeout)

  return data
}
