import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import _slugify from 'slugify'
import { useI18n } from 'vue-i18n'
import { useNow } from '@/composables/useNow'
import { useTimify } from '@/composables/useTimify'
import { useDeviceStore } from '@/stores/device'
import { useUiStore } from '@/stores/ui'

const appointmentColors = {
  noShow: { color: '#4E4E4E' },
  'Abholung / Service': { color: '#49DE80' },
  Beratung: { color: '#2D98AF' },
  'Kostenloser Sehtest': { color: '#96C3FF' },
  Sehtest: { color: '#96C3FF' },
  Führerscheinsehtest: { color: '#8A94E9' },
  Reklamation: { color: '#FF9999' },
  'Store Admin 30 Min': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'Store Admin 60 Min': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'Eyetest Walk-In': { color: '#96C3FF' },
  'ACUITY EYE TEST (EU)': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'ACUITY EYETEST (CH)': {
    backgroundColor: '#E6E6E6',
    borderColor: '#E6E6E6',
    textColor: '#676767'
  },
  'ACUITY STILBERATUNG': { color: '#ccb0be' }
}

export const useAppointmentsStore = defineStore('appointments', () => {

  const { t } = useI18n()
  const now = useNow()
  const deviceStore = useDeviceStore()
  const uiStore = useUiStore()
  const timify = useTimify()

  const enabled = ref(false)
  const companies = ref([])
  const appointments = ref([])
  const modifyDialogOpen = ref(false)
  const entryDialogOpen = ref(false)
  const selectedAppointmentId = ref(null)

  ;(async () => {
    companies.value = await timify.companies()
  })()

  const selectedAppointment = computed(() => {
    return viuAppointments.value?.find((appointment) => appointment.id === selectedAppointmentId.value)
  })

  watch(selectedAppointmentId, (value) => {
    if (value) {
      modifyDialogOpen.value = true
    }
  })
  
  watch(modifyDialogOpen, (value) => {
    if (!value) {
      selectedAppointmentId.value = null
    }
  })

  const currentCompany = computed(() => {
    return companies.value?.find(company => {
      return company.externalId?.toLowerCase() === deviceStore.settings.warehouse?.toLowerCase()
    })
  })

  const currentCompanyId = computed(() => {
    return currentCompany.value?.id
  })

  async function updateAppointments() {
    const response = await timify.appointments(currentCompanyId.value)
    appointments.value = response?.data || []
  }

  watch([currentCompanyId, () => uiStore.showDeviceMarketSettingsDialog], async ([companyId, showDeviceMarketSettingsDialog]) => {
    if (companyId && !showDeviceMarketSettingsDialog) {
      enabled.value = true
      appointments.value = []
      await updateAppointments()
    } else {
      enabled.value = false
    }
  }, { immediate: true })

  const viuAppointments = computed(() => {
    return appointments.value
      .filter((appointment) => appointment.type === 'booking')
      .map((appointment) => {
        return {
          ...appointment,
          serviceKey: slugify(appointment.title || '')
        }
      })
      .map((appointment) => {
        const { bookings } = appointment
        // we have multiple identical bookings, one for each resource I guess
        const [booking] = bookings
        const { interval } = booking
        const noShow = appointment.tags.some(tag => tag.externalId === 'no_show')
        const colors = noShow ? appointmentColors['noShow'] : appointmentColors[appointment.title] || {}
        const style = {
          backgroundColor: colors.backgroundColor || colors?.color,
          color: colors.textColor || '#ffffff'
        }
        return {
          ...appointment,
          start: new Date(interval.begin),
          end: new Date(interval.end),
          viuTitle: appointmentTitle(appointment),
          noShow,
          colors,
          style
        }
      })
  })

  const upcomingViuAppointments = computed(() => {
    return viuAppointments.value.filter((appointment) => {
      const start = new Date(appointment.start)
      start.setMinutes(start.getMinutes() + 10)
      return start >= now.value
    })
  })

  function appointmentTitle(appointment) {
    const [customer] = appointment.singleCustomers
    const employee = appointment.resources?.find((resource) => Boolean(resource.externalId))
    const employeeNamePieces = employee?.name.split(' ')
    const employeeFirstName = employeeNamePieces?.[0]
    const employeeLastName = employeeNamePieces?.[employeeNamePieces.length - 1]
    const employeeInitials = [
      Array.from(employeeFirstName || '')[0],
      Array.from(employeeLastName || '')[0]
    ]
      .filter(Boolean)
      .join('')
    const customerLastNameInitial = Array.from(customer?.lastName || '')[0]
    const customerName = [
      customer?.firstName,
      customerLastNameInitial ? `${customerLastNameInitial}.` : ''
    ]
      .filter(Boolean)
      .join(' ')
    let title
    if (['store_admin_30_min', 'store_admin_60_min'].includes(appointment.serviceKey)) {
      title = [
        t(`appointments.services.${appointment.serviceKey}`),
        employeeInitials ? `(${employeeInitials})` : ''
      ]
        .filter(Boolean)
    } else {
      title = [customerName, employeeInitials ? `(${employeeInitials})` : '']
        .filter(Boolean)
    }
  
    return title
  }

  return {
    enabled,
    appointments,
    viuAppointments,
    upcomingViuAppointments,
    companies,
    currentCompany,
    currentCompanyId,
    selectedAppointmentId,
    selectedAppointment,
    modifyDialogOpen,
    entryDialogOpen,
    updateAppointments
  }
})

function slugify(name, options = {}) {
  return _slugify(name, {
    lower: true,
    remove: /[./\\()]/,
    locale: 'de',
    ...options
  }).replace(/-/g, '_')
}